import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import { mapEdgesToNodes } from '../utils/helpers'
import Figure from '../components/figure'
import BlockContent from '../components/block-content'
import Breadcrumbs from '../components/breadcrumbs'
import slugify from 'slugify'

const PhysicianPage = props => {
  const { data, errors } = props
  const physicians = data && data.physicians && mapEdgesToNodes(data.physicians)

  return (
    <>
      <SEO title='Våre leger' />
      <Breadcrumbs crumbs={[{
        title: 'Våre leger'
      }]} />
      <header className='bg-peach-lighter block lg:grid grid-cols-2'>
        <div className='w-full h-full'>
          {data.image && (
            <Img fluid={data.image.fluid} />
          )}
        </div>
        <div className='w-full px-5 pt-12 pb-16 lg:pt-16 lg:px-12 xl:px-20'>
          <div className='h-full flex flex-col justify-center'>
            <h1>Våre leger</h1>
            <p className='lead'>Selv om du ikke er i direkte kontakt med lege ved bestilling, har vi dyktige og erfarne leger bak kulissene som hjelper deg. Våre leger bruker god tid på å behandle ditt helseskjema</p>
          </div>
        </div>
      </header>
      <div className='max-w-screen-xl mx-auto py-8 md:py-24'>
        <div className='mx-5 md:mx-10 lg:mx-20'>
          {physicians && physicians.length > 0 && physicians.map((physician, index) => (
            <div
              className={`mb-8 md:mb-24 flex-col flex ${ index % 2 == 0 ? 'md:flex-row-reverse' : 'md:flex-row' }`}
              key={physician.id}
              id={slugify(physician.name, {lower: true})}
            >
              <div className={`w-full md:w-4/5 mb-5 md:mb-0`}>
                {physician.image && (
                  <Figure node={physician.image} />
                )}
              </div>
              <div className={`flex flex-col justify-center md:mx-8 w-full md:w-4/5`}>
                {physician.name && (
                  <h3 className='font-serif text-primary'>{physician.name}</h3>
                )}
                {physician.position && (
                  <p className='font-bold'>{physician.position} i Maja</p>
                )}
                {physician.description && (
                  <BlockContent blocks={physician.description} />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default PhysicianPage

export const query = graphql`
  query GetAllPhysicians {
    image: sanityImageAsset(id: {
      eq: "image-b5b13609dcfe3faef5608cbb760e0db768d2f6b6-960x960-png"
    }) {
      fluid(maxWidth: 1280) {
        ...GatsbySanityImageFluid
      }
    }
    physicians: allSanityPhysician {
      edges {
        node {
          id
          name
          image {
            alt
            asset {
              fluid(maxWidth: 452) {
                ...GatsbySanityImageFluid
              }
            }
          }
          description: _rawDescription
          position
        }
      }
    }
  }
`